import React from 'react';


const SectionsLoadingDiv = ({sectionValues, setSelectedSectionIndex, selectedSectionIndex, isSectionAvailable, answeredQuestionIds, selectedQuestion, changeQuestion}) => {
    
    const changeSection = (index) => {
        setSelectedSectionIndex(index + 1);
    }

    return (
        <div className='sections-container py-2' style={{ width: '20%', overflowY: 'auto' }}>
            {sectionValues.map((section, index) => {
                return (
                    <div key={index} className='sections-selector-sidebar'>
                        <div onClick={() => { changeSection(index) }} style={{ cursor: 'pointer' }}
                            className={selectedSectionIndex - 1 == index ? 'active-section-container sidebar-selectable-container' : 'inactive-section-container sidebar-selectable-container'}>
                            <p className='m-0'>{section.section_name}</p>
                        </div>
                        {index == selectedSectionIndex - 1 && isSectionAvailable && <div className='d-flex flex-wrap gap-3 m-1 questions-list '>
                            {sectionValues[index].questions.map((question, index) => {
                                return (<div className={answeredQuestionIds[selectedSectionIndex-1].has(question._id) ? selectedQuestion-1 === index? "questions-numbers is-answered-question active-question" :"questions-numbers is-answered-question":selectedQuestion - 1 == index ? "questions-numbers active-question" : "questions-numbers inactive-question"} key={index} onClick={() => { changeQuestion(index + 1) }}>{index + 1}</div>)
                            })}
                        </div>}
                    </div>
                )
            })}
            <ul className="circles">
                <li></li> <li></li> <li></li> <li></li> <li></li>
                <li></li> <li></li> <li></li> <li></li> <li></li>
            </ul>

        </div>
    );
}

export default SectionsLoadingDiv;