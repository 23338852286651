import React from 'react';
import examcountdowntimer from '../../assets/exam-countdown-timer.png';

const CountDownTimer = ({remainingDays, remainingHours, remainingMinutes, remainingSeconds}) => {
        
    return (
        <div className='container'>
            <div className='row align-items-center'>
                <div className='col-md-6'>
                    <img src={examcountdowntimer} className='img-fluid' 
                        alt='Exam Countdown' />
                </div>
                <div className='col-md-6'>
                    <div className='countdown-timer-container'>
                        <p className='examstarts-header'>Countdown to Exam</p>
                        <p className='exam-countdown-timer'>
                            <span className='countdown-info'>
                                {remainingDays}<br />
                                <label>Day(s)</label>
                            </span>
                            <span className='time-seperator'>:</span>
                            <span className='countdown-info'>
                                {remainingHours}<br />
                                <label>Hour(s)</label>
                            </span>
                            <span className='time-seperator'>:</span>
                            <span className='countdown-info'>
                                {remainingMinutes}<br />
                                <label>Minute(s)</label>
                            </span>
                            <span className='time-seperator'>:</span>
                            <span className='countdown-info'>
                                {remainingSeconds}<br />
                                <label>Second(s)</label>
                            </span>
                        </p>
                    </div>
                    <p className='funny-note'>Brains on, stress off. Good luck!</p>
                </div>
            </div>
        </div>
    );
}

export default CountDownTimer;