import React from 'react';
import './default.css'; // Import the CSS file
import HistoricalAnalysis from './historicalAnalysis';
import TopicWisePerformance from './topicwisePerformance';
import PeerComparison from './peerComparision';
import ExamMarksTable from './examsMarksTable';

const StudentDashboard = () => {
  return (
    <div className='main-dashboard-page-container'>
      <h2 className='dashboard-page-header text-center'>Welcome to your Dashboard!</h2>

      <div className="chart-row">
        <div className=' row'>
          <div className='col-xxl-6 col-md-6'>
            <div className="chart-container-dashboard">
              <HistoricalAnalysis />
            </div>
          </div>
          <div className='col-xxl-6 col-md-6'>
            <div className="chart-container-dashboard">
              <TopicWisePerformance />
            </div>
          </div>
          <div className='col-xxl-6 col-md-6'>
            <div className="chart-container-dashboard">
              <PeerComparison />
            </div>
          </div>
          <div className='col-xxl-6 col-md-6'>
            <div className="chart-container-dashboard">
              <ExamMarksTable />
            </div>
          </div>
        </div>
      </div>

      {/* You can add more charts here if needed */}
    </div>
  );
};

export default StudentDashboard;
