import './spinner-loader.css';

export default function SpinnerLoader() {
    return (
        <div className="text-center position-relative" >
            <div className='spinner-position-container'>
                <div className="spinner-grow text-info" role="status"> </div>
                <div className="spinner-grow text-info" role="status"> </div>
                <div className="spinner-grow text-info" role="status"> </div>
            </div>
            <div className="spinner-border" role="status" ></div>
        </div>
    )
}