import React from 'react';
import './login.css'; // Import CSS file
import { useNavigate } from 'react-router-dom'; // Use useNavigate instead of useHistory
import Cookies from 'js-cookie';
import {useState, useEffect} from 'react';

export default function Login(props) {
    const [credentials, setCredentials] = useState({email:"", password:""})
    const [error_msg, setError] = useState("")

    useEffect(()=>{
        if(window.location.pathname !== '/') window.location.href="/"
    },[])
    
    const login_call=async ()=>{
        if(credentials.email.length === 0 || credentials.password.length === 0){
            setError("Please fill the credentials to login")
        }
        else{
            await fetch(process.env.REACT_APP_API_URL+"/login",{
                method:"POST",
                headers:{
                    "Content-Type": "application/json"
                },
                body: JSON.stringify(credentials)
            })
            .then(async(res)=>{
                const result = await res.json()
                console.log(result,"this is result")
                if(!result.success){                             
                    setError(result.message)                
                }
                else{ 
                    if((result?.result?.user?.is_registered && result.result.user.user_type === 'student') || result.result.user.user_type === 'superAdmin' ) {
                        // Cookies.set("nexus_student_login",true,{expires:9999});
                        Cookies.set("nexus_student_id",result.result.user._id,{expires:9999});
                        props.SwitchLoginStatus(true);
                    }
                    else {
                    setError("The Entered Email is not Registered")
                    }
                   
                }

            })
            .catch((err)=>{
                console.log(err)
                setError("Somethings wrong!!! Please try again!")
                
            })
        }
    }

    const InputChange=(event)=>{
        event.preventDefault()
        if(event.keyCode === 13){
            login_call()
        }
        else{
            setCredentials({...credentials,[event.target.name]:event.target.value})
        }
    }
    return(
        <div className='login_page d-flex flex-column justify-content-center align-items-end'>
          <div className='login_container'>
            <p className='logo_img_container'>
                <img src="/logos/nexsaa-logo.jpg"  alt="NexusIqSolutions" height={80}/>
            </p>
            <h2>Login</h2>
            <div className="">
                <label htmlFor="email" className="input_text">Email</label>
                <input type="email" name="email" placeholder="Enter Email" className="input_element" id="email" onKeyUp={InputChange}/>
            </div>
            <br/>
            <div className="">
                <label htmlFor="password" className="input_text">Password &nbsp;</label>
                <input type="password" name="password" placeholder="Enter Password" id="password" className="input_element" onKeyUp={InputChange}/>
            </div>
            <br/>
            
            <input type="button" className="login_button" value="Login" onClick={login_call}/>
            <br />
            <p className='forgot_pwd mb-0'>
                <a href="/forgotPassword" >Forgot Password</a>
            </p>
            
            <p id="error_msg">{error_msg}</p>
          </div>
        </div>
        
    )
}
