import React from 'react';
import './header.css'; // Import CSS file
import logo from '../../assets/nexsaa-white-logo.png';
import userMenuIcon from '../../assets/profile.svg';
import Cookies from 'js-cookie';
import { useContext } from 'react';
import Context from '../../user_details';

export default function Header() {
  const {user} = useContext(Context);

  const logout = ()=>{
    // Cookies.remove("nexus_student_login");
    Cookies.remove("nexus_student_id");
    window.location.href="/"
  }
  return (
    <header className="header">
      <div className="logo">
        <img src={logo} alt="Logo" />
      </div>
      <div className="dropdown user-info">
        
        <div className="d-flex justify-content-center align-items-center" type="button" id="dropdownMenuButton" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
          <img src={userMenuIcon} height={19}/>
        </div>
        <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
          <a className="dropdown-item" href="/" onClick={logout}>Logout</a>
        </div>
        <p className="p-0 m-0 pe-2 header-username">Welcome, {user.username}</p>
      </div>
    </header>
  );
}
