import {convertToUTCWithOffsetFormatted, convertToUTCWithOffsetFormattedWithSeconds, getCurrentUTCDateTime, getCurrentUTCDateTimeWithSeconds} from '../../helpers/timeHelpers';

export const  tryInitializeWebcam = (webcamDivRef, retryCount) => {
    const webcamElement = webcamDivRef.current;
    if (!webcamElement) {
        retryCount++;
        if (retryCount <= 5) { // Retry 5 times (adjust as needed)
            setTimeout(() => tryInitializeWebcam(webcamDivRef, retryCount), 1000);
        }
        return;
    }

    // Initialize draggable functionality here
    let isDragging = false;
    let startX, startY;

    const onMouseDown = (e) => {
        isDragging = true;
        startX = e.clientX - webcamElement.offsetLeft;
        startY = e.clientY - webcamElement.offsetTop;
        document.addEventListener('mousemove', onMouseMove);
        document.addEventListener('mouseup', onMouseUp);
    };

    const onMouseMove = (e) => {
        if (!isDragging) return;
        const newLeft = e.clientX - startX;
        const newTop = e.clientY - startY;
        webcamElement.style.left = `${newLeft}px`;
        webcamElement.style.top = `${newTop}px`;
    };

    const onMouseUp = () => {
        isDragging = false;
        document.removeEventListener('mousemove', onMouseMove);
        document.removeEventListener('mouseup', onMouseUp);
    };

    webcamElement.addEventListener('mousedown', onMouseDown);

    return () => {
        webcamElement.removeEventListener('mousedown', onMouseDown);
        document.removeEventListener('mousemove', onMouseMove);
        document.removeEventListener('mouseup', onMouseUp);
    };
};

export const detectChanges = (previousPredictions, currentPredictions) => {
    // Helper function to filter predictions by class
    const filterPredictions = (predictions, className) => predictions.filter(prediction => prediction.class === className);

    // Filter predictions by person, face, and hand classes
    const personsInPreviousFrame = filterPredictions(previousPredictions, 'person');
    const personsInCurrentFrame = filterPredictions(currentPredictions, 'person');
    const facesInPreviousFrame = filterPredictions(previousPredictions, 'face');
    const facesInCurrentFrame = filterPredictions(currentPredictions, 'face');
    const handsInPreviousFrame = filterPredictions(previousPredictions, 'hand');
    const handsInCurrentFrame = filterPredictions(currentPredictions, 'hand');

    // Compare the number of detected objects
    const movementDetected = personsInPreviousFrame.length !== personsInCurrentFrame.length ||
        facesInPreviousFrame.length !== facesInCurrentFrame.length ||
        handsInPreviousFrame.length !== handsInCurrentFrame.length;

    // Function to detect position changes for a specific class
    const detectPositionChanges = (previousFrame, currentFrame) => {
        if (previousFrame.length !== currentFrame.length) {
            return true;
        }

        let positionChangesDetected = false;
        for (let i = 0; i < previousFrame.length; i++) {
            const prevItem = previousFrame[i];
            const currItem = currentFrame[i];

            // Calculate distance between centers of bounding boxes
            const prevCenterX = prevItem.bbox[0] + prevItem.bbox[2] / 2;
            const prevCenterY = prevItem.bbox[1] + prevItem.bbox[3] / 2;
            const currCenterX = currItem.bbox[0] + currItem.bbox[2] / 2;
            const currCenterY = currItem.bbox[1] + currItem.bbox[3] / 2;

            const distance = Math.sqrt(Math.pow(currCenterX - prevCenterX, 2) + Math.pow(currCenterY - prevCenterY, 2));

            // Define a threshold for position change detection (e.g., 3 pixels)
            const positionChangeThreshold = 3;

            if (distance > positionChangeThreshold) {
                positionChangesDetected = true;
                break;
            }
        }
        return positionChangesDetected;
    };

    // Check for position changes in persons, faces, and hands
    const positionChangesDetected = detectPositionChanges(personsInPreviousFrame, personsInCurrentFrame) ||
        detectPositionChanges(facesInPreviousFrame, facesInCurrentFrame) ||
        detectPositionChanges(handsInPreviousFrame, handsInCurrentFrame);

    return { movementDetected, positionChangesDetected };
};

export const captureAndStream = async (webcamRef, user, exam_id, socket, isExamSubmitted, isFirstFrame, previousPredictions, objectDetectionModel) => {
    if (webcamRef.current && !isExamSubmitted) {
        const imageSrc = webcamRef.current.getScreenshot();
        if (imageSrc && objectDetectionModel) {
            const img = new Image();
            img.onload = async () => {
                const predictions = await objectDetectionModel.detect(img);

                if (isFirstFrame) {
                    previousPredictions = predictions;
                    isFirstFrame = false;
                    return;
                }

                // Compare current predictions with previous predictions to detect movement and position changes
                const { movementDetected, positionChangesDetected } = detectChanges(previousPredictions, predictions);

                if (movementDetected == true) {
                    socket.emit('video_stream', { user_id: user._id, exam_id, image: imageSrc });
                    console.log('Movement detected, sending image to server.', movementDetected);
                } else if (positionChangesDetected == true) {
                    socket.emit('video_stream', { user_id: user._id, exam_id, image: imageSrc });
                    console.log('Position changes detected, sending image to server.', positionChangesDetected);
                } else {
                    console.log('No significant movement or position changes detected.');
                }

                // Update previous predictions with current predictions
                previousPredictions = predictions;
            };
            img.src = imageSrc;
        }
    } else {
        console.warn('Webcam reference is not yet available.');
    }
};

export const setTimer = (current,endDate, setTimerValue) => {
    // let current = new Date();
    let EndDate = new Date(endDate);
    // EndDate.setMinutes(EndDate.getMinutes()-30);
    // EndDate.setHours(EndDate.getHours()-5);
    let totalMS = EndDate.getTime() - current.getTime();
    let totalSeconds = Math.floor(totalMS / 1000);
    let totalHours = Math.floor(totalSeconds / 3600)
    totalSeconds -= totalHours * 3600;
    let totalMinutes = Math.floor(totalSeconds / 60);
    totalSeconds -= totalMinutes * 60;
    setTimerValue([totalHours, totalMinutes, totalSeconds]);
}

export const runTimer = (isExamSubmitted, timerValue, setTimerValue, submitAnswers, setAdditionalInfo, setTimeIntervals) => {
    let runningTimer = setInterval(() => {
        if (isExamSubmitted) {
            clearInterval(runningTimer);
            return;
        }
        if (timerValue[0] <= 0 && timerValue[1] <= 0 && timerValue[2] <= 0) {
            clearInterval(runningTimer);
            // console.log(timeSpentPerQuestions," time spent in runtimer");
            
            submitAnswers();
            setAdditionalInfo('Your exam has been automatically submitted because the time is over.')
            return;
        } else {
            if (timerValue[2] > 0) {
                let temporaryTimerValue = timerValue;
                temporaryTimerValue[2] -= 1;
                setTimerValue([...temporaryTimerValue]);
            } else {
                if (timerValue[1] > 0) {
                    let temporaryTimerValue = timerValue;
                    temporaryTimerValue[1] -= 1;
                    temporaryTimerValue[2] = 59;
                    setTimerValue([...temporaryTimerValue]);
                } else {
                    if (timerValue[0] > 0) {
                        let temporaryTimerValue = timerValue;
                        temporaryTimerValue[0] -= 1;
                        temporaryTimerValue[1] = 59;
                        temporaryTimerValue[2] = 59;
                        setTimerValue([...temporaryTimerValue]);
                    }
                }
            }
        }
    }, 1000);
    setTimeIntervals(prev => [...prev, runningTimer]);
}

export const calculateDuration = async(Exam, setRemainingDays, setRemainingHours, setRemainingMinutes, setRemainingSeconds, setExamWillStartTime) => {
    var startDate = await convertToUTCWithOffsetFormattedWithSeconds(Exam.startDate, Exam.timeZoneOffset.offset);
    startDate = new Date(startDate)
    var endDate = await getCurrentUTCDateTimeWithSeconds()
    endDate = new Date(endDate);
    
    setInterval(function () {
        console.log(startDate, endDate,"---utc")
        const diffMs = startDate.getTime() - endDate.getTime();
        console.log("diff is utc", diffMs)
        const diffDays = Math.floor(diffMs / 86400000); // Days
        const diffHrs = Math.floor((diffMs % 86400000) / 3600000); // Hours
        const diffMins = Math.floor((diffMs % 3600000) / 60000); // Minutes
        const seconds = Math.floor((diffMs % (1000 * 60)) / 1000);
        if (diffMs > 0) {
            setRemainingDays(diffDays);
            setRemainingHours(diffHrs);
            setRemainingMinutes(diffMins);
            setRemainingSeconds(seconds);
            setExamWillStartTime(`${diffDays > 0 ? `${diffDays} <span>Day(s)</span>, ` : ''}${diffHrs} Hour(s) : ${diffMins} Minute(s) : ${seconds} Seconds`);
            endDate.setTime(endDate.getTime() + 1000);
        } else {
            window.location.reload();
        }
    }, 1000);
    return '';
};

export const checkExamAvailability = async(Exam, setTimerValue, setExamAvailability, setRemainingDays, setRemainingHours, setRemainingMinutes, setRemainingSeconds, setExamWillStartTime) => {
    if (Object.keys(Exam).length > 0) {
        if (Object.keys(Exam).includes('startDate')) {
           
            const {offset} = Exam.timeZoneOffset;
            var current = await getCurrentTimeInTimezone(offset);
            current = new Date(current);
            let start = new Date(Exam.startDate);
            // start.setMinutes(start.getMinutes());
            // start.setHours(start.getHours());
            let end = new Date(Exam.endDate);
            // end.setMinutes(end.getMinutes());
            // end.setHours(end.getHours());
            const examAvailability = current >= start && current <= end;
            // alert(`${current},${start},${end}, availability" ${examAvailability}`)
            if (examAvailability) {
                setTimer(current,end, setTimerValue);
            } else if (current < start) {
                calculateDuration(Exam, setRemainingDays, setRemainingHours, setRemainingMinutes, setRemainingSeconds, setExamWillStartTime);
            }
            setExamAvailability(examAvailability);
        } else {
            setExamAvailability(true);
        }
    } else {
        // clearInterval(timer);
    }
}

export const convertToUtc = async(dateString, offsetString) => {
    // Parse the date string into a Date object
    const localDate = new Date(dateString);

    // Extract the sign, hours, and minutes from the offset string correctly
    const sign = offsetString[0]; // "+" or "-"
    const [hours, minutes] = offsetString.slice(1).split(':'); // Extract hours and minutes

    // Calculate the total offset in minutes
    const totalOffsetMinutes = (parseInt(hours) * 60) + parseInt(minutes);

    // Convert the total offset into milliseconds and adjust based on sign
    const offsetInMillis = (sign === "+" ? -1 : 1) * totalOffsetMinutes * 60 * 1000;

    // Calculate UTC time by adding/subtracting the offset from the local time
    const utcTime = new Date(localDate.getTime() + offsetInMillis);

    return utcTime;
}

export async function getCurrentTimeInTimezone(offsetString) {
    // Extract the sign, hours, and minutes from the offset string
    const sign = offsetString[0] === '-' ? -1 : 1;
    const [hours, minutes] = offsetString.slice(1).split(':').map(Number);

    // Calculate the total offset in hours
    const offset = sign * (hours + minutes / 60);

    // Create a new Date object representing the current time in UTC
    const now = new Date();

    // Get the current time in UTC (milliseconds since January 1, 1970)
    const utcTime = now.getTime() + (now.getTimezoneOffset() * 60000);

    // Calculate the time in the desired time zone by adding the offset (converted to milliseconds)
    const timezoneTime = new Date(utcTime + (offset * 3600000));

    // Format the date to a readable string
    return timezoneTime
}

