import React, { useEffect, useState } from 'react';
import { debounce } from 'lodash';
import axios from 'axios';
import PreviousNextButtonStrip from './prevNextButtonStrip';

const QuestionsDiv = ({timeSpentPerQuestions, setTimeSpentPerQuestions,prevQuestionTimer, setPrevQuestionTimer, setTimerInstance, sectionValues, setQuestionsLoader, setSelectedSectionIndex, prevQuestionIndex, timerInstance, prevTime, Exam, isExamAvailable, isExamSubmitted, timerValue, submitAnswers, questionsLoader, questions, sectionWiseAnswers, setSectionWiseAnswers, selectedSectionIndex, selectedQuestion, setAnsweredQuestionIds, answeredQuestionIds, prevSectionIndex, setPrevSectionIndex, setprevQuestionIndex, setSelectedQuestion, setPrevTime, user}) => {
   
    const optionType = { 1: "radio", 2: "radio", 3: "checkbox", 4: "textarea", 5: "file" };
    
    const [isTimerStarted, setIsTimerStarted] = useState(false);

    /*useEffect(() => {
        globalTimeSpentPerQuestions = timeSpentPerQuestions;
        if(timeSpentPerQuestions.length > 0 && !isTimerStarted) {
            setIsTimerStarted(true);
            initializeTimerperQuestion();
        }
    }, [timeSpentPerQuestions]);*/

    useEffect(() => {
        
        if (timeSpentPerQuestions.length > 0 && !isTimerStarted) {
            setIsTimerStarted(true);
            initializeTimerperQuestion();
        }
    }, [timeSpentPerQuestions, isTimerStarted]);


    useEffect(() => {
        try {
            if (!questionsLoader) {
                clearInterval(timerInstance);
                let tempTimeSpentValues = [...timeSpentPerQuestions];
                tempTimeSpentValues[prevSectionIndex - 1].time_spent[questions[prevQuestionIndex - 1]._id] += parseInt((new Date() - prevQuestionTimer)/1000);
                setPrevQuestionTimer(new Date());
                setPrevTime(0);
                setprevQuestionIndex(selectedQuestion);
                initializeTimerperQuestion();
                // console.log("changing timespent to", tempTimeSpentValues);
                setTimeSpentPerQuestions([...tempTimeSpentValues]);
                if (prevSectionIndex != selectedSectionIndex) {
                    setPrevSectionIndex(selectedSectionIndex);
                }        
            }
            else {
                setSelectedQuestion(selectedQuestion);
            }
        }
        catch(e) {
            console.error(e);
        }
        return () => clearInterval(timerInstance);
    }, [selectedQuestion, questionsLoader])

    const previousQuestion = () => {
        setQuestionsLoader(true);
        setQuestionsLoader(false);
        setSelectedQuestion(selectedQuestion - 1);
        saveExamBeforeSubmit();
    }

    const nextQuestion = () => {
        setQuestionsLoader(true);
        setQuestionsLoader(false);
        setSelectedQuestion(selectedQuestion + 1);
        saveExamBeforeSubmit();
    }

    const initializeTimerperQuestion = () => {
        if (timerInstance) clearInterval(timerInstance);
        console.log('I am in initializeTimerperQuestion')
        const newTimerInstance = setInterval(() => {
            setPrevTime(prev => prev + 1);
        }, 1000);
    
        setTimerInstance(newTimerInstance);
    }

    const nextSection = () => {
        setSelectedQuestion(1);
        setSelectedSectionIndex(selectedSectionIndex + 1);
    }

    const prevSection = () => {
        setSelectedQuestion(1);
        setSelectedSectionIndex(selectedSectionIndex - 1);
    }

    const changeAnswer = (question, optionId) => {
        const sectionIndex = selectedSectionIndex - 1;
        const questionId = question._id;

        setSectionWiseAnswers(prevAnswers => {
            const updatedSectionAnswers = [...prevAnswers];
            const updatedQuestionAnswers = { ...updatedSectionAnswers[sectionIndex] };

            let currentAnswers = updatedQuestionAnswers[questionId] || [];

            if (question.question_type == 3) {
                // Multiple choice (checkbox) - toggle the answer
                if (currentAnswers.includes(optionId)) {
                    currentAnswers = currentAnswers.filter(id => id !== optionId);
                } else {
                    currentAnswers.push(optionId);
                }
            } else if (question.question_type == 2 || question.question_type == 1) {
                // Single choice (radio button) - replace existing answer
                currentAnswers = [optionId];
                if (!answeredQuestionIds[selectedSectionIndex - 1].has(questionId)) {
                    var temporaryAnsweredQuestionIds = answeredQuestionIds;
                    temporaryAnsweredQuestionIds[selectedSectionIndex - 1] = new Set([...answeredQuestionIds[selectedSectionIndex - 1], questionId]);
                    setAnsweredQuestionIds([...temporaryAnsweredQuestionIds]);
                }
            }

            updatedQuestionAnswers[questionId] = currentAnswers;
            updatedSectionAnswers[sectionIndex] = updatedQuestionAnswers;

            return updatedSectionAnswers;
        });
    };

    const debouncedSaveExam = debounce(async (finalAnswers) => {
        try {
            await axios.post(`${process.env.REACT_APP_API_URL}/exam/save-before-submit`, {
                client_id: user.firm_id,
                answers: finalAnswers,
                user_id: user._id,
                exam_id: Exam._id
            });
            // console.log("Before submit Exam data saved successfully");
        } catch (error) {
            console.error("Error Before submit exam data saving:", error);
        }
    }, 300); // Adjust debounce time as needed
    
    const saveExamBeforeSubmit = () => {
        let finalAnswers = [...sectionWiseAnswers];
        if (sectionWiseAnswers.length > 0) {
            finalAnswers = finalAnswers.map((sectionAnswers, index) => ({
                section_id: sectionValues[index].section_id,
                answers: sectionAnswers,
            }));
        }
        debouncedSaveExam(finalAnswers);
    };

    return (
        <div className='questions-container w-100 d-flex flex-column gap-3'>
            {isExamAvailable && !Object.keys(Exam).includes('startDate') && timerValue &&
                <div className='d-flex justify-content-end gap-2 me-1'>
                    <div className="fs-6 fw-bold text-center d-flex align-items-center">
                        &#128336;&nbsp; {timerValue[0]} hrs : {timerValue[1]} min : {timerValue[2]} sec
                    </div>
                    {!isExamSubmitted && <div className='btn btn-success' onClick={submitAnswers}>Submit Section</div>}
                </div>
            }
            <div style={{ overflow: "auto" }}>
                <h3 className='main-question'><span className='q-label'>Q.</span> <span dangerouslySetInnerHTML={{ __html: questions[selectedQuestion - 1]?.question }}></span></h3>
                {!questionsLoader && questions[selectedQuestion - 1].options.map((option, index) => {
                    return (
                        <div key={index} className='d-flex align-items-center answers-options-container m-2 ms-4'>
                            <input type={optionType[questions[selectedQuestion - 1].question_type]}
                                name="option" id={option._id} className='options-input-selection'
                                checked={sectionWiseAnswers[selectedSectionIndex - 1][questions[selectedQuestion - 1]._id]?.includes(option._id)}
                                onChange={() => { changeAnswer(questions[selectedQuestion - 1], option._id) }}
                            />
                            <label className='options-label' htmlFor={option._id} style={{ fontWeight: 'normal' }}>{option.option}</label>
                        </div>
                    )
                })}
            </div>
            <PreviousNextButtonStrip Exam={Exam} selectedQuestion={selectedQuestion} selectedSectionIndex={selectedSectionIndex} prevSection={prevSection} previousQuestion={previousQuestion} questions={questions} nextQuestion={nextQuestion} nextSection={nextSection}/>
        </div>
    );
}

export default QuestionsDiv;