// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.header {
  font-size: 13px;
  /* background-color: #020d36; */
  background-color: #00b3f4;
  color: #fff;
  padding: 2px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
header.header .logo {
  position: absolute;
  margin-left: 15px;
  margin-top: 45px;
  z-index: 999;
}
.logo img {
  width: 200px; /* Adjust size as needed */
  padding: 5px;
}
.user-info {
  width: 100%;
  text-align: right;
  padding: 5px;
  display: flex;
  flex-direction: row-reverse;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: center;
}
.header-username {
  margin-right: 10px;
}
`, "",{"version":3,"sources":["webpack://./src/components/Header/header.css"],"names":[],"mappings":"AAAA;EACE,eAAe;EACf,+BAA+B;EAC/B,yBAAyB;EACzB,WAAW;EACX,YAAY;EACZ,aAAa;EACb,8BAA8B;EAC9B,mBAAmB;AACrB;AACA;EACE,kBAAkB;EAClB,iBAAiB;EACjB,gBAAgB;EAChB,YAAY;AACd;AACA;EACE,YAAY,EAAE,0BAA0B;EACxC,YAAY;AACd;AACA;EACE,WAAW;EACX,iBAAiB;EACjB,YAAY;EACZ,aAAa;EACb,2BAA2B;EAC3B,iBAAiB;EACjB,2BAA2B;EAC3B,mBAAmB;AACrB;AACA;EACE,kBAAkB;AACpB","sourcesContent":[".header {\n  font-size: 13px;\n  /* background-color: #020d36; */\n  background-color: #00b3f4;\n  color: #fff;\n  padding: 2px;\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n}\nheader.header .logo {\n  position: absolute;\n  margin-left: 15px;\n  margin-top: 45px;\n  z-index: 999;\n}\n.logo img {\n  width: 200px; /* Adjust size as needed */\n  padding: 5px;\n}\n.user-info {\n  width: 100%;\n  text-align: right;\n  padding: 5px;\n  display: flex;\n  flex-direction: row-reverse;\n  flex-wrap: nowrap;\n  justify-content: flex-start;\n  align-items: center;\n}\n.header-username {\n  margin-right: 10px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
