import React, { useEffect, useState, useContext } from 'react';
import axios from 'axios';
import { Bar } from 'react-chartjs-2';
import { Chart as ChartJS, BarElement, CategoryScale, LinearScale, Tooltip, Legend } from 'chart.js';
import Context from '../../user_details';

// Register Chart.js components
ChartJS.register(BarElement, CategoryScale, LinearScale, Tooltip, Legend);

const PeerComparison = ({ studentId }) => {
  const { user } = useContext(Context);
  const [comparisonData, setComparisonData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [timeRange, setTimeRange] = useState('Last 10 Exams');

  useEffect(() => {
    const fetchComparisonData = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/student/peer-comparision/${user._id}`, {
          params: { examCount:timeRange }
        });
        
        setComparisonData(response.data);
        
      } catch (error) {
        if (error.response && error.response.status === 404) {
          setError('No data available.');
        } else {
          console.error('Error fetching historical analysis:', error);
          setError('Failed to fetch historical analysis.');
        }
      } finally {
        setLoading(false);
      }
    };

    fetchComparisonData();
  }, [studentId, timeRange]);

  const handleTimeRangeChange = (event) => {
    setTimeRange(event.target.value);
    setLoading(true); // Optionally set loading to true when changing time range
  };

  if (loading) return <p>Loading...</p>;
  if (error) return <p className='text-center mb-0 no-data'>{error}</p>;

  // Prepare data for Chart.js
  const labels = comparisonData.map(item => item.examName);
  const topScorerData = comparisonData.map(item => item.topScorePercentage);
  const studentData = comparisonData.map(item => item.studentPercentage);

  const data = {
    labels: labels,
    datasets: [
      {
        label: 'Top Scorer Percentage',
        data: topScorerData,
        backgroundColor: 'rgba(75,192,192,0.7)',
        borderColor: 'rgba(75,192,192,1)',
        borderWidth: 1,
        borderRadius: 5, // Rounded corners for the bars
        barThickness: 30, // Control bar width
        hoverBackgroundColor: 'rgba(75,192,192,0.9)',
        hoverBorderColor: 'rgba(75,192,192,0.9)',
        hoverBorderWidth: 2,
        shadowOffsetX: 3, // Simulating shadow effect
        shadowOffsetY: 3,
        shadowBlur: 6,
        shadowColor: 'rgba(0, 0, 0, 0.2)',
      },
      {
        label: 'Your Percentage',
        data: studentData,
        backgroundColor: 'rgba(255,99,132,0.7)',
        borderColor: 'rgba(255,99,132,1)',
        borderWidth: 1,
        borderRadius: 5, // Rounded corners for the bars
        barThickness: 30, // Control bar width
        hoverBackgroundColor: 'rgba(255,99,132,0.9)',
        hoverBorderColor: 'rgba(255,99,132,0.9)',
        hoverBorderWidth: 2,
        shadowOffsetX: 3, // Simulating shadow effect
        shadowOffsetY: 3,
        shadowBlur: 6,
        shadowColor: 'rgba(0, 0, 0, 0.2)',
      },
    ],
  };

  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top',
      },
      tooltip: {
        callbacks: {
          label: function(tooltipItem) {
            return `${tooltipItem.dataset.label}: ${tooltipItem.raw.toFixed(2)}%`;
          },
        },
      },
    },
    scales: {
      x: {
        beginAtZero: true,
        categoryPercentage: 0.6,
        barPercentage: 0.7
      },
      y: {
        beginAtZero: true,
        ticks: {
          callback: function(value) {
            return value + '%';
          },
        },
      }
    },
    layout: {
      padding: {
        left: 10,
        right: 10,
        top: 10,
        bottom: 10,
      },
    }
    
  };

  return (
    <div className="chart-sub-container">
      <h3>Peer Comparison</h3>
      <div className='form-elements-container row float-end'>
          <div className='form-info-entry-area'>
            <select value={timeRange} onChange={handleTimeRangeChange} className="form-select">
              <option value="Latest Exam">Latest Exam</option>
              <option value="Last 5 Exams">Last 5 Exams</option>
              <option value="Last 10 Exams">Last 10 Exams</option>
              <option value="Last 20 Exams">Last 20 Exams</option>
              <option value="All">All</option>
            </select>
          </div>
      </div>
      <Bar data={data} options={options} />
    </div>
  );
};

export default PeerComparison;
