import React, { useEffect, useState, useContext } from 'react';
import axios from 'axios';
import {
  Chart as ChartJS,
  LineElement,
  PointElement,
  CategoryScale,
  LinearScale,
  Tooltip,
  Filler
} from 'chart.js';
import { Line } from 'react-chartjs-2';
import Context from '../../user_details';

// Register Chart.js components
ChartJS.register(LineElement, PointElement, CategoryScale, LinearScale, Tooltip, Filler);

const HistoricalAnalysis = () => {
  const { user } = useContext(Context);
  const [historicalData, setHistoricalData] = useState([]);
  const [chartData, setChartData] = useState({});
  const [timeRange, setTimeRange] = useState('Last One Week'); // Default time range
  const [error, setError] = useState(null);

  useEffect(() => {
    if (user._id) fetchHistoricalAnalysis();
  }, [timeRange, user._id]);

  useEffect(() => {
    prepareChartData(historicalData);
  }, [historicalData]);

  const fetchHistoricalAnalysis = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/student/historical-analysis/${user._id}`,
        {
          params: { timeRange },
        }
      );

      const data = response.data;

      if (!data || data.length === 0) {
        setError('No data available for the selected time range.');
        setHistoricalData([]);
        setChartData({});
        return;
      }

      setHistoricalData(data);
    } catch (error) {
      if (error.response && error.response.status === 404) {
        setError('No data available.');
      } else {
        console.error('Error fetching historical analysis:', error);
        setError('Failed to fetch historical analysis.');
      }
    }
  };

  const prepareChartData = (data) => {
    if (!data || !data.map) {
      setError('Invalid data format received.');
      return;
    }

    const labels = data.map((item) => item.examDate.split('T')[0]);
    const marks = data.map((item) => item.scoredPercentage);

    setChartData({
      labels,
      datasets: [
        {
          label: 'Scored %',
          data: marks,
          backgroundColor: 'rgba(75, 192, 192, 0.5)', // More opacity for a softer look
          borderColor: 'rgba(75, 192, 192, 1)',
          borderWidth: 2,
          tension: 0.4,
          pointBackgroundColor: 'rgba(75, 192, 192, 1)',
          pointBorderColor: '#3a4f96',
          pointBorderWidth: 2,
          pointRadius: 6,
          pointHoverRadius: 8,
          fill: true,
          shadowOffsetX: 5, // Simulating 3D effect
          shadowOffsetY: 5,
          shadowBlur: 10,
          shadowColor: 'rgba(0, 0, 0, 0.3)',
          hoverBackgroundColor: 'rgba(75, 192, 192, 1)',
        },
      ],
    });
  };

  const handleTimeRangeChange = (event) => {
    setTimeRange(event.target.value);
    setError(null); // Clear any previous error when changing time range
  };

  return (
    <div className="chart-sub-container">
      <h3>Performance Over Time</h3>
      <div className='form-elements-container row float-end'>
        <div className='form-info-entry-area'>
          <select value={timeRange} onChange={handleTimeRangeChange} className='form-select'>
            <option value="Last One Week">Last One Week</option>
            <option value="Last One Month">Last One Month</option>
            <option value="Last One Year">Last One Year</option>
            <option value="All Till Date">All Till Date</option>
          </select>
        </div>
      </div>
      {error ? (
        <p className='text-center mb-0 no-data'>{error}</p>
      ) : historicalData.length > 0 ? (
        <div className="line-chart">
          <Line data={chartData}  
            options={{
              responsive: true,
              scales: {
                x: {
                  grid: {
                    display: false,
                  }
                },
                y: {
                  grid: {
                    color: 'rgba(200, 200, 200, 0.2)',
                  }
                },
              },
            }}
            />
        </div>
      ) : (
        <p>No data available for the selected time range.</p>
      )}
    </div>
  );
};

export default HistoricalAnalysis;
