import React from 'react';

const ErrorMessage = ({errorMessage, fullscreenimage}) => {
        
    return (
        <div className='h-100 w-100'>
            <div className="no-exams">
                <div className="no-exams-text-container">
                    {errorMessage != "" ? (
                        <>
                        <div className='container'>
                            <div className='row justify-content-center align-items-center'>
                                <div className='col-md-7 position-relative'>
                                    <p className='exams_environment_img'>
                                        <img src={fullscreenimage} alt='Exam Instructions' 
                                            className='img-fluid' />
                                    </p>
                                    <p className='exams_completed_message'>
                                        {errorMessage} 
                                    </p>
                                </div>
                            </div>
                        </div>
                            
                        </>

                    ) : (
                        <>
                            <div className="spinner-border text-primary" role="status"></div>
                        </>
                    )}
                </div>
            </div>
        </div>
    );
}

export default ErrorMessage;