import React from 'react';


const ThankYouModal = ({additionalInfo}) => {
        
    return (
        <div className="modal fade exam-submitted-container" data-bs-backdrop="static" data-bs-keyboard="false" style={{ backdropFilter: 'blur(10px)' }} id="examSubmitSuccessModal" tabIndex="-1" aria-labelledby="examSubmitSuccessModalLabel" aria-hidden="true">
            <div className="modal-dialog">
                <div className="modal-content">
                    
                    <div className='submission-message'>
                        <div className="wave">
                            <h1 className="modal-title" id="examSubmitSuccessModalLabel">
                                <span className='success-icon'>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" fill="currentColor" className="bi bi-check-circle" viewBox="0 0 16 16">
                                        <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16" />
                                        <path d="m10.97 4.97-.02.022-3.473 4.425-2.093-2.094a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-1.071-1.05" />
                                    </svg>
                                </span>
                                Thank You!
                            </h1>
                        </div>
                        <div className='submission-message-bottom'>
                            <p>Your Exam has been saved successfully.</p>
                            {additionalInfo && ( <p><small className='additionalInfo-warning'>{additionalInfo}</small></p> ) }
                            <button type="button" className="btn close-btn" data-bs-dismiss="modal" onClick={() => window.close()}>Close</button>
                            {/*<p className='contact-free'>
                                Any technical Issues while writing the exam..? 
                                <br />
                                Please feel free to contact the technical team at helpdesk@nexusiq-solutions.com</p>*/}
                        </div>
                    </div>


                </div>
            </div>
        </div>
    );
}

export default ThankYouModal;