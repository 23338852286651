import React from 'react';
import logowhite from '../../assets/nexsaa-white-logo.png';


const HeaderStrip = ({Exam, isExamAvailable, timer_warning, timerValue, isExamSubmitted, submitAnswers, isSubmitting}) => {
        
    return (
        <div className='row align-items-center'>
            <div className='col-md-3'>
                <div className="main-logo">
                    <img src={logowhite} alt="Logo" />
                </div>
            </div>
            <div className='col-md-5'>
                <p className="text-center exam-title">{Exam.examName}</p>
            </div>
            <div className='col-md-4'>
                {isExamAvailable && Object.keys(Exam).includes('startDate') &&
                    <div className='row align-items-center'>
                        <div className="col-md-7 text-end">
                            <p className='countdown-timer'>
                                {/* &#128336;  */}
                                <span className='timer-image'>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" fill="currentColor" className="bi bi-hourglass-split" viewBox="0 0 16 16">
                                        <path d="M2.5 15a.5.5 0 1 1 0-1h1v-1a4.5 4.5 0 0 1 2.557-4.06c.29-.139.443-.377.443-.59v-.7c0-.213-.154-.451-.443-.59A4.5 4.5 0 0 1 3.5 3V2h-1a.5.5 0 0 1 0-1h11a.5.5 0 0 1 0 1h-1v1a4.5 4.5 0 0 1-2.557 4.06c-.29.139-.443.377-.443.59v.7c0 .213.154.451.443.59A4.5 4.5 0 0 1 12.5 13v1h1a.5.5 0 0 1 0 1zm2-13v1c0 .537.12 1.045.337 1.5h6.326c.216-.455.337-.963.337-1.5V2zm3 6.35c0 .701-.478 1.236-1.011 1.492A3.5 3.5 0 0 0 4.5 13s.866-1.299 3-1.48zm1 0v3.17c2.134.181 3 1.48 3 1.48a3.5 3.5 0 0 0-1.989-3.158C8.978 9.586 8.5 9.052 8.5 8.351z" />
                                    </svg>
                                </span>
                                &nbsp;
                                <span className={timer_warning ? "text-danger" : ""}>{timerValue[0]} <span>hrs</span> : {timerValue[1]} <span>min</span> : {timerValue[2]} <span>sec</span></span>
                            </p>
                        </div>
                        {!isExamSubmitted &&
                            <div className="col-md-5">
                                <div className='submit-exam-button' onClick={submitAnswers} id='submitExamsButton' disabled={isSubmitting}>
                                    Submit Exam
                                    <span>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-chevron-double-right" viewBox="0 0 16 16">
                                            <path fillRule="evenodd" d="M3.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L9.293 8 3.646 2.354a.5.5 0 0 1 0-.708" />
                                            <path fillRule="evenodd" d="M7.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L13.293 8 7.646 2.354a.5.5 0 0 1 0-.708" />
                                        </svg>
                                    </span>
                                </div>
                            </div>}
                    </div>
                }
            </div>
        </div>
    );
}

export default HeaderStrip;