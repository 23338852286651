import './App.css';
import {BrowserRouter, Routes, Route} from 'react-router-dom';
import Login from './pages/Login/login';
import Home from './pages/Home/home';
import Exams from './components/Exams/exams';
import ExamReports from './components/ExamReports/examreports';
import StartExam from './components/StartExam/startexam';
import ExamEnvironment from './components/ExamEnvironment/examEnvironment';
import Default from './components/Default/default';
import {useState, useEffect} from 'react';
import Context from './user_details';
import Cookies from 'js-cookie';
import axios from 'axios';
import CryptoJS from 'crypto-js';
import ForgotPassword from './components/ForgotPassword/forgotPassword';
import SpinnerLoader from './helpers/spinner-loader-main-screen';


function App() {
  const [isLoggedIn,setIsLoggedIn]=useState(Cookies.get("nexus_student_id"));
  const[userDetails, setUserDetails] = useState({});
  const [isUserVerified, setUserVerification] = useState(false);
  const [unregisteredUserId, setUnregisteredUser] = useState({});
  const [loader, setLoader] = useState(true);

  useEffect(() => {
    getUserData();
  }, []);
  
  useEffect(()=>{
    if(Object.keys(userDetails).length > 0) {
      setIsLoggedIn(true);
      setLoader(false);
    }
    console.log("user details in app",userDetails)
  },[userDetails]);

  const SwitchLoginStatus=(Status)=>{
    setIsLoggedIn(Status);
    // Cookies.set("nexus_student_login",Status,{expires:9999})
    if(Object.keys(userDetails).length === 0 && Status) {
      getUserData();
    }
  }
  
  const getUserData = async() => {
    if (Cookies.get("nexus_student_id")) {
      await fetch(process.env.REACT_APP_API_URL + "/user/" + Cookies.get("nexus_student_id"))
        .then(async response => {
          const data = await response.json();
          if (data.success) {
            setUserDetails(data.result);
          }
          else {
            Cookies.remove("nexus_exam_id");
            // Cookies.remove("nexus_exam_login");
            setIsLoggedIn(false);
            setLoader(false);
          }
        })
        .catch(err => {
          Cookies.remove("nexus_exam_id");
          // Cookies.remove("nexus_exam_login");
          alert("something went wrong")
        })
    }
    else {
      setLoader(false);
    }
  }

  // const verifyUser = async(email, userId) => {
  //   const result = await axios.post(`${process.env.REACT_APP_API_URL}/verifyAuthentication`, 
  //                   {
  //                       examinee: CryptoJS.AES.encrypt(email,  process.env.REACT_APP_EXAM_SECRET_KEY).toString(),
  //                       token: CryptoJS.AES.encrypt(userId,  process.env.REACT_APP_EXAM_SECRET_KEY).toString()
  //                   });
  //   if (!result.data.success) {
  //      setUserVerification(false);
  //   }
  //   else {
  //       setUnregisteredUser(result.data.user_id);
  //       setUserVerification(true);
  //   }

  //  }

 if(!loader) {
  if(!isLoggedIn){
    let queryparams = Array.from(new URLSearchParams(window.location.search))
    if (queryparams.length === 2) {
      let examineeVerifier=false, tokenVerifier=false, examinee='', token='';
      for ( let i =0 ; i < queryparams.length ; i++) {
        if (queryparams[i][0] == 'examinee') {
          examineeVerifier++;
          examinee = queryparams[i][1];
        }
        else if(queryparams[i][0] == 'token') {
          tokenVerifier++;
          token = queryparams[i][1];
        }
        if (i == queryparams.length - 1) {
          if(examineeVerifier && tokenVerifier) {
            // verifyUser(examinee,token);
            if ( true ) {
              return (
                <BrowserRouter>
                  <Routes>
                    <Route path ="start/:exam_id" element={<StartExam unregisteredUser={{examinee, token, unregisteredUserId}}/>}/>
                    <Route path ="exams/start/:exam_id" element={<ExamEnvironment unregisteredUser={{examinee, token, unregisteredUserId}} />}/>
                  </Routes>
                </BrowserRouter>
              )
            } 
            else {
              return (
                <BrowserRouter>
                  <Routes>
                    <Route path="/" element={<Login isLoggedIn={isLoggedIn} SwitchLoginStatus={SwitchLoginStatus} setUserDetails={setUserDetails}/>} />
                    <Route path="forgotPassword" element={<ForgotPassword/>}/>
                    <Route path="*" element={<Login isLoggedIn={isLoggedIn} SwitchLoginStatus={SwitchLoginStatus} setUserDetails={setUserDetails}/>}/>
                  </Routes>
                </BrowserRouter>
              )
            }
          }
          else {
            return (
              <BrowserRouter>
                <Routes>
                  <Route path="/" element={<Login isLoggedIn={isLoggedIn} SwitchLoginStatus={SwitchLoginStatus} setUserDetails={setUserDetails}/>} />
                  <Route path="forgotPassword" element={<ForgotPassword/>}/>
                  <Route path="*" element={<Login isLoggedIn={isLoggedIn} SwitchLoginStatus={SwitchLoginStatus} setUserDetails={setUserDetails}/>}/>
                </Routes>
              </BrowserRouter>
            )
          }
        }
      }
    } 
    else return (
          <BrowserRouter>
            <Routes>
              <Route path="/" element={<Login isLoggedIn={isLoggedIn} SwitchLoginStatus={SwitchLoginStatus} setUserDetails={setUserDetails}/>} />
              <Route path="forgotPassword" element={<ForgotPassword/>}/>
              <Route path="*" element={<Login isLoggedIn={isLoggedIn} SwitchLoginStatus={SwitchLoginStatus} setUserDetails={setUserDetails}/>}/>
            </Routes>
          </BrowserRouter>
        )
  }
 else{
 if(Object.keys(userDetails).length > 0){
  return (
    <Context.Provider value={{user: userDetails}}>
          <BrowserRouter>
            <Routes>
              <Route path="/login" element={<Login/>}/>
              <Route path="/" element={<Home/>}>
                <Route exact path="" element={<Default/>}/>
                <Route path="exams">
                  <Route path="" element={<Exams/>}/>
                </Route>
                <Route path="exam-reports" element={<ExamReports/>}/>
                <Route path="start/:exam_id" element={<StartExam/>}/>
              </Route>
              <Route path ="exams/start/:exam_id" element={<ExamEnvironment/>}/>
            </Routes>
          </BrowserRouter>
    </Context.Provider>
   )
 }
 else{
  return <div>Loading.....</div>
 }
 }
 }
 else {
  // return <div>Loading.......</div>
  return <div className="ht60vh w-100 d-flex justify-content-center align-items-center main-screen-loader">
            <SpinnerLoader x={'sample-class-name'} />
            <p className='loader-message'>Please Wait.. It'll just take a moment..</p>
          </div>
 }
}

export default App;
