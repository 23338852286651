exports.getCurrentUTCDateTime = async () => {
    // Get the current date and time
    const now = new Date();

    // Format the UTC date to "YYYY-MM-DDTHH:MM"
    const year = now.getUTCFullYear();
    const month = String(now.getUTCMonth() + 1).padStart(2, '0');
    const day = String(now.getUTCDate()).padStart(2, '0');
    const hours = String(now.getUTCHours()).padStart(2, '0');
    const minutes = String(now.getUTCMinutes()).padStart(2, '0');

    return `${year}-${month}-${day}T${hours}:${minutes}`;
}
exports.getCurrentUTCDateTimeWithSeconds = async () => {
    // Get the current date and time
    const now = new Date();

    // Format the UTC date to "YYYY-MM-DDTHH:MM"
    const year = now.getUTCFullYear();
    const month = String(now.getUTCMonth() + 1).padStart(2, '0');
    const day = String(now.getUTCDate()).padStart(2, '0');
    const hours = String(now.getUTCHours()).padStart(2, '0');
    const minutes = String(now.getUTCMinutes()).padStart(2, '0');
    const seconds = String(now.getUTCSeconds()).padStart(2, '0');

    return `${year}-${month}-${day}T${hours}:${minutes}:${seconds}`;
}
exports.convertToUTCWithOffsetFormatted = async (dateString, offset) => {
    // Parse the date string to a Date object
    const date = new Date(dateString);

    // Extract the sign, hours, and minutes from the offset
    const sign = offset[0];
    const [hours, minutes] = offset.slice(1).split(':').map(Number);

    // Convert the offset to minutes
    const offsetInMinutes = (hours * 60 + minutes) * (sign === '+' ? -1 : 1);

    // Apply the offset to the date
    const utcTime = new Date(date.getTime() + offsetInMinutes * 60000);

    // Format the UTC date to "YYYY-MM-DDTHH:MM"
    const year = utcTime.getFullYear();
    const month = String(utcTime.getMonth() + 1).padStart(2, '0');
    const day = String(utcTime.getDate()).padStart(2, '0');
    const hoursFormatted = String(utcTime.getHours()).padStart(2, '0');
    const minutesFormatted = String(utcTime.getMinutes()).padStart(2, '0');

    return `${year}-${month}-${day}T${hoursFormatted}:${minutesFormatted}`;
}

exports.convertToUTCWithOffsetFormattedWithSeconds = async (dateString, offset) => {
    // Parse the date string to a Date object
    const date = new Date(dateString);

    // Extract the sign, hours, and minutes from the offset
    const sign = offset[0];
    const [hours, minutes] = offset.slice(1).split(':').map(Number);

    // Convert the offset to minutes
    const offsetInMinutes = (hours * 60 + minutes) * (sign === '+' ? -1 : 1);

    // Apply the offset to the date
    const utcTime = new Date(date.getTime() + offsetInMinutes * 60000);

    // Format the UTC date to "YYYY-MM-DDTHH:MM:SS"
    const year = utcTime.getFullYear();
    const month = String(utcTime.getMonth() + 1).padStart(2, '0');
    const day = String(utcTime.getDate()).padStart(2, '0');
    const hoursFormatted = String(utcTime.getHours()).padStart(2, '0');
    const minutesFormatted = String(utcTime.getMinutes()).padStart(2, '0');
    const secondsFormatted = String(utcTime.getSeconds()).padStart(2, '0'); // Add seconds

    return `${year}-${month}-${day}T${hoursFormatted}:${minutesFormatted}:${secondsFormatted}`;
}

exports.converttoLocalfromUTCWithOffset = async (dateString, offset) => {
    // Parse the date string to a Date object
    const date = new Date(dateString);

    // Extract the sign, hours, and minutes from the offset
    const sign = offset[0];
    const [hours, minutes] = offset.slice(1).split(':').map(Number);

    // Convert the offset to minutes
    const offsetInMinutes = (hours * 60 + minutes) * (!sign === '+' ? -1 : 1);

    // Apply the offset to the date
    const utcTime = new Date(date.getTime() + offsetInMinutes * 60000);

    // Format the UTC date to "YYYY-MM-DDTHH:MM"
    const year = utcTime.getFullYear();
    const month = String(utcTime.getMonth() + 1).padStart(2, '0');
    const day = String(utcTime.getDate()).padStart(2, '0');
    const hoursFormatted = String(utcTime.getHours()).padStart(2, '0');
    const minutesFormatted = String(utcTime.getMinutes()).padStart(2, '0');

    return `${year}-${month}-${day}T${hoursFormatted}:${minutesFormatted}`;
}
