import React, { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import Context from '../../user_details';

const ExamMarksTable = ({ studentId }) => {
    const { user } = useContext(Context);
    const [examMarks, setExamMarks] = useState([]);
    const [examCount, setExamCount] = useState(10); // Default to last 10 exams

    useEffect(() => {
        // Fetch the exam marks data from the API
        const fetchExamMarks = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_API_URL}/student/exam-marks-table/${user._id}?examCount=${examCount}`);
                setExamMarks(response.data);
            } catch (error) {
                console.error('Error fetching exam marks:', error);
            }
        };

        fetchExamMarks();
    }, [studentId, examCount]);

    const handleExamCountChange = (e) => {
        setExamCount(parseInt(e.target.value, 10));
    };

    return (
        <div className="chart-sub-container">
            <h3>Exam Results Overview</h3>
            <div className='form-elements-container row float-end'>
                <div className='form-info-entry-area'>
                    <select id="examCount" className="form-select" value={examCount} onChange={handleExamCountChange} >
                        <option value="5">Last 5 Exams</option>
                        <option value="10">Last 10 Exams</option>
                        <option value="20">Last 20 Exams</option>
                        <option value="0">All Exams</option>
                    </select>
                </div>
            </div>
            
            <div className='row'>
                <div className="table-responsive">
                    <table className="table table-bordered">
                        <thead>
                            <tr>
                                <th>Exam Name</th>
                                <th>Scored Marks</th>
                                <th>Out Of</th>
                            </tr>
                        </thead>
                        <tbody>
                            {examMarks.length > 0 ? (
                                examMarks.map((exam, index) => (
                                    <tr key={index}>
                                        <td>{exam.examName}</td>
                                        <td>{exam.totalScored}</td>
                                        <td>{exam.totalOutOf}</td>
                                    </tr>
                                ))
                            ) : (
                                <tr>
                                    <td colSpan="3" className='text-center no-data'>No data available</td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    );
};

export default ExamMarksTable;
