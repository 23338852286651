// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.questions-numbers.is-answered-question {
  background: rgb(137, 255, 3) !important;
}
.questions-numbers.is-answered-question.active-question {
  background: white !important;
}`, "",{"version":3,"sources":["webpack://./src/components/ExamEnvironment/examEnvironment.scss"],"names":[],"mappings":"AACI;EACI,uCAAA;AAAR;AACQ;EACI,4BAAA;AACZ","sourcesContent":[".questions-numbers {\n    &.is-answered-question {\n        background: rgb(137, 255, 3) !important;\n        &.active-question {\n            background:  white!important;\n        }\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
