import React from 'react';

const NotAvailable = ({fullscreenimage}) => {
        
    return (
        <>
            <div className='container mt-5'>
                <div className='row justify-content-center align-items-center mt-2'>
                    <div className='col-md-7 position-relative'>
                        <p className='exams_environment_img mt-3'>
                            <img src={fullscreenimage} alt='Exam Instructions' 
                                className='img-fluid' />
                        </p>
                        <div className='exams_completed_message message02'>
                            <h2 className='sorry-message'>
                                Sorry 
                                <span>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-emoji-frown" viewBox="0 0 16 16">
                                        <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16"/>
                                        <path d="M4.285 12.433a.5.5 0 0 0 .683-.183A3.5 3.5 0 0 1 8 10.5c1.295 0 2.426.703 3.032 1.75a.5.5 0 0 0 .866-.5A4.5 4.5 0 0 0 8 9.5a4.5 4.5 0 0 0-3.898 2.25.5.5 0 0 0 .183.683M7 6.5C7 7.328 6.552 8 6 8s-1-.672-1-1.5S5.448 5 6 5s1 .672 1 1.5m4 0c0 .828-.448 1.5-1 1.5s-1-.672-1-1.5S9.448 5 10 5s1 .672 1 1.5"/>
                                    </svg>
                                </span>
                            </h2>
                            <p className='text-center'>
                                It seems you already attempted the Exam.
                                <br />
                                (or) 
                                <br />
                                The Exam you are looking for is not available at this time.
                            </p>
                        </div>
                    
                    </div>
                </div>
            </div>
        </>
    );
}

export default NotAvailable;