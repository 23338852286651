// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* home.css */

.home-container {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 5px;
    background-color: #f9f9f9;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  
  h2 {
    margin-bottom: 10px;
  }
  
  p {
    line-height: 1.5;
  }
  
  .content.app-main-content{
    /* background-color: #f1f5f7; */
    background-color: #fff;
    /* height: 95.5%; */
    min-height: calc(100vh - 64px);
  }`, "",{"version":3,"sources":["webpack://./src/pages/Home/home.css"],"names":[],"mappings":"AAAA,aAAa;;AAEb;IACI,gBAAgB;IAChB,cAAc;IACd,aAAa;IACb,sBAAsB;IACtB,kBAAkB;IAClB,yBAAyB;IACzB,wCAAwC;EAC1C;;EAEA;IACE,mBAAmB;EACrB;;EAEA;IACE,gBAAgB;EAClB;;EAEA;IACE,+BAA+B;IAC/B,sBAAsB;IACtB,mBAAmB;IACnB,8BAA8B;EAChC","sourcesContent":["/* home.css */\n\n.home-container {\n    max-width: 800px;\n    margin: 0 auto;\n    padding: 20px;\n    border: 1px solid #ccc;\n    border-radius: 5px;\n    background-color: #f9f9f9;\n    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);\n  }\n  \n  h2 {\n    margin-bottom: 10px;\n  }\n  \n  p {\n    line-height: 1.5;\n  }\n  \n  .content.app-main-content{\n    /* background-color: #f1f5f7; */\n    background-color: #fff;\n    /* height: 95.5%; */\n    min-height: calc(100vh - 64px);\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
