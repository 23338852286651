import React from 'react';
import Modal from 'react-bootstrap/Modal';

const FinalConfirmationModal = ({showConfirmSubmitModal, setShowConfirmSubmitModal, confirmSubmitAnswers}) => {
        
    return (
        <Modal show={showConfirmSubmitModal} className='submitExam-container'>
            <Modal.Header className='d-block border-0'>
                <Modal.Title>
                    <p className='icon-representation'>
                        <svg xmlns="http://www.w3.org/2000/svg" width="55" height="55" fill="currentColor" className="bi bi-exclamation-circle" viewBox="0 0 16 16">
                            <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16"/>
                            <path d="M7.002 11a1 1 0 1 1 2 0 1 1 0 0 1-2 0M7.1 4.995a.905.905 0 1 1 1.8 0l-.35 3.507a.552.552 0 0 1-1.1 0z"/>
                        </svg>
                    </p>
                    <p className='submitExam-header text-center'>
                        Are You sure?
                    </p>
                    <p className='submitExam-content text-center mb-0'>
                        This is your final submission.
                    </p>
                
                </Modal.Title>
            </Modal.Header>
            <Modal.Footer className='d-flex justify-content-center border-0 pt-0'>
                <div className='btn btn-outline-secondary no-cancel' data-dismiss="modal" onClick={() => {setShowConfirmSubmitModal(0)}}>No, Cancel</div>
                <div className='btn btn-outline-success yes-submit' onClick={() => {confirmSubmitAnswers(1)}}>Yes, Submit</div>
            </Modal.Footer>
        </Modal>
    );
}

export default FinalConfirmationModal;