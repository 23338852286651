import React from 'react';
import './footer.css'; // Import CSS file

export default function Footer() {
  return (
    <footer className='app-main-footer' >
      <h4 className='text-center'>Copyright © 2024 NexusIQ Solutions. <br />All rights reserved.</h4>
    </footer>
  );
}
