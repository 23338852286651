import React from 'react';



const PreviousNextButtonStrip = ({Exam, selectedQuestion, selectedSectionIndex, prevSection, previousQuestion, questions, nextQuestion, nextSection}) => {
        
    return (
        <div className='d-flex mt-auto'>
            <div className='prevnext-btn-container'>
                {selectedQuestion === 1 && Object.keys(Exam).includes("startDate") && selectedSectionIndex > 1 && <div className='btn btn-primary previous-qtn' onClick={prevSection}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-caret-left-fill" viewBox="0 0 16 16">
                        <path d="m3.86 8.753 5.482 4.796c.646.566 1.658.106 1.658-.753V3.204a1 1 0 0 0-1.659-.753l-5.48 4.796a1 1 0 0 0 0 1.506z" />
                    </svg>
                    Previous
                </div>}
                {selectedQuestion > 1 && <div className='btn btn-primary previous-qtn' onClick={previousQuestion}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-caret-left-fill" viewBox="0 0 16 16">
                        <path d="m3.86 8.753 5.482 4.796c.646.566 1.658.106 1.658-.753V3.204a1 1 0 0 0-1.659-.753l-5.48 4.796a1 1 0 0 0 0 1.506z" />
                    </svg>
                    Previous</div>}
                {selectedQuestion < questions.length && <div className='btn btn-primary next-qtn' onClick={nextQuestion}>Next
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-caret-right-fill" viewBox="0 0 16 16">
                        <path d="m12.14 8.753-5.482 4.796c-.646.566-1.658.106-1.658-.753V3.204a1 1 0 0 1 1.659-.753l5.48 4.796a1 1 0 0 1 0 1.506z" />
                    </svg>
                </div>}
                {selectedQuestion === questions.length && Object.keys(Exam).includes("startDate") && selectedSectionIndex < Exam.sections.length && <div className='btn btn-primary next-qtn' onClick={nextSection}>
                    Next
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-caret-right-fill" viewBox="0 0 16 16">
                        <path d="m12.14 8.753-5.482 4.796c-.646.566-1.658.106-1.658-.753V3.204a1 1 0 0 1 1.659-.753l5.48 4.796a1 1 0 0 1 0 1.506z" />
                    </svg>
                </div>}
            </div>
        </div>
    );
}

export default PreviousNextButtonStrip;