import React from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';


const FullScreenPromptModal = ({showFullscreenPrompt, fullscreenimage, setShowFullscreenPrompt}) => {

    const enterFullscreen = () => {

        const element = document.documentElement;
        if (element.requestFullscreen) {
            element.requestFullscreen().catch(console.error);
        } else if (element.mozRequestFullScreen) { // Firefox
            element.mozRequestFullScreen().catch(console.error);
        } else if (element.webkitRequestFullscreen) { // Chrome, Safari, Opera
            element.webkitRequestFullscreen().catch(console.error);
        } else if (element.msRequestFullscreen) { // IE/Edge
            element.msRequestFullscreen().catch(console.error);
        }
        setShowFullscreenPrompt(false);
    };
        
    return (
        <Modal show={showFullscreenPrompt} className='full-scren-info examinee_details_form_container'>
            <Modal.Header>
                <Modal.Title>
                    <p className='fullscreen-img'>
                        <img src={fullscreenimage} className='img-fluid' alt="Logo" />
                    </p>
                    <p>
                        Our Examination System will work only on the <strong>FULL SCREEN</strong> mode.
                        <br />
                        Please <strong>Enter</strong> to the full screen mode so that you can attend the exam.
                    </p>
                    <Button variant="primary" className='submit_details' onClick={enterFullscreen}>Enter the Fullscreen Mode</Button>
                    <p className='mb-0 warning-message'>
                        (Please don't quit the full screen otherwise it will be considered as malpractice and your exam may get terminated.)
                    </p>
                </Modal.Title>
            </Modal.Header>
        </Modal>
    );
}

export default FullScreenPromptModal;