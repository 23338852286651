import React, { useState, useEffect, useContext } from 'react';
import { useParams, useNavigate, useSearchParams } from 'react-router-dom';
import './startexam.css';
import instructions from '../../assets/online-test.png';
import Context from '../../user_details';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import CryptoJS from 'crypto-js';
import Offcanvas from 'react-bootstrap/Offcanvas';
import axios from 'axios';
import SpinnerLoader from '../../helpers/spinner-loader';
import { convertToUtc, getCurrentTimeInTimezone } from '../ExamEnvironment/helpers';


export default function ExamStart(props) {

  const [exam, setExam] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const navigate = useNavigate();
  const { exam_id } = useParams();
  const [searchParams] = useSearchParams();
  let { user } = useContext(Context);
  const [instructionsChecked, setInstructionsChecked] = useState(false);
  const [isformSubmitted, setFormSubmission] = useState(Object.keys(props).length === 0);
  const [show, setShow] = useState(!isformSubmitted);
  const [errorDisplay, setErrorDisplay] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [examinee,setExaminee] = useState(undefined);
  const [token, setToken] = useState(undefined); 
  const [examineeDetails, setExamineeDetails] = useState({});
  const [submitFormLoader, setSubmitFormLoader] = useState(true);

  const calculateDuration = (start, end) => {
    const startDate = new Date(start);
    const endDate = new Date(end);
    const diffMs = endDate - startDate;

    const diffDays = Math.floor(diffMs / 86400000); // Days
    const diffHrs = Math.floor((diffMs % 86400000) / 3600000); // Hours
    const diffMins = Math.floor((diffMs % 3600000) / 60000); // Minutes

    return `${diffDays > 0 ? `${diffDays} Day(s), ` : ''}${diffHrs} Hour(s) and ${diffMins} Minute(s)`;
  };


  useEffect(() => {
    if (Object.keys(props).length > 0) {
      console.log(props, "props are")
      user = { _id: props.unregisteredUser.unregisteredUserId }
     
    }
  })

  useEffect(() => {
    const getExamineeDetails = async() => {
      let token = searchParams.get('token');
      token = CryptoJS.enc.Utf8.stringify(CryptoJS.enc.Base64.parse(token));
      // token = CryptoJS.enc.Utf8.stringify(CryptoJS.enc.Base64.parse(token));
      let {data: ExamineeDetails} = await axios.get(`${process.env.REACT_APP_API_URL}/ums/admin/read/${token}`)
      console.log("examinee details", ExamineeDetails)
      if (ExamineeDetails.success) {
        setExamineeDetails(ExamineeDetails.result)
      } else {
        triggerErrorPopUp("unable to fetch examinee details! Please fill the details");
        setExamineeDetails({
          first_name: "",
          last_name: "",
          contact_number: ""
        });
      }
    }
    if(show && !isformSubmitted) {
      getExamineeDetails();
    }
  }, [show]);

  useEffect(() => {
    if(Object.keys(examineeDetails).length > 0) {
      setSubmitFormLoader(false);
    }
  }, [examineeDetails])

  useEffect(() => {
    const fetchExamDetails = async () => {
      try {
        const response = await fetch(process.env.REACT_APP_API_URL + `/exam/read-conduct/${exam_id}`);
        if (!response.ok) {
          console.log(response.data);
          setError('It seems you already attempted this exam or Network response is not ok.');
          setLoading(false);
          return false;
        }
        const data = await response.json();
        const endDate = await getCurrentTimeInTimezone(data.timeZoneOffset.offset);
        // alert(endDate)
        if(new Date(data.endDate) < endDate){
          setShow(false);
          setError('Exam time is expired.');
        } else {
          const duration = calculateDuration(data.startDate, data.endDate);

          let totalQuestions = 0;
          let totalMarks = 0;

          (data.sections).forEach(section => {
            section.questions.forEach(question => {
              totalQuestions++;
              totalMarks += question.marks;
            });
          });

          setExam({ ...data, duration, totalQuestions, totalMarks });
        }
        
        setLoading(false);
      } catch (error) {
        console.log(error)
        setError(error.message);
        setLoading(false);
      }
    };

    fetchExamDetails();
  }, []);

  const startExam = () => {
    if (!instructionsChecked || !isformSubmitted) {
      alert("Please check the 'I have read Instructions carefully' checkbox before starting the exam.");
      return;
    }

    if (Object.keys(props).length > 0) {
      window.open(`/exams/start/${exam_id}?examinee=${examinee}&token=${token}`);
    }
    else {
      window.open("/exams/start/" + exam_id);
    }
  };
 
  const triggerErrorPopUp = (errormessage = "") => {
    setErrorMessage(errormessage);
    setErrorDisplay(true);
    const errorTimer = setTimeout(() => {     
      setErrorDisplay(false);
      setErrorMessage("");
    }, 2000)
  }

  const submitUnregisteredUserForm = async() => {
    try {
      let first_name = document.getElementById("unregisteredUserFormFirstName").value;
      let last_name = document.getElementById("unregisteredUserFormLastName").value;
      let phone_number= document.getElementById("unregisteredUserFormPhoneNumber").value;
      let token = searchParams.get('token');
      if (first_name.length > 0 && last_name.length > 0 && phone_number.length > 0) {
        const result = await axios.post(`${process.env.REACT_APP_API_URL}/ums/updateUnregisteredUser`, { first_name, last_name, contact_number: phone_number, token });
        if (result.data.success == true) {
          setExaminee(CryptoJS.enc.Base64.stringify(CryptoJS.enc.Utf8.parse(searchParams.get('examinee'))));
          setToken(CryptoJS.enc.Base64.stringify(CryptoJS.enc.Utf8.parse(token)));
          setFormSubmission(true);
          setShow(false);
        }
        else {
          triggerErrorPopUp("Somnething went wrong! Please Try again!")
        }
      }
      else {
        triggerErrorPopUp("Please Enter all details!!!")
      }
      
      
    }
    catch(e) {
      triggerErrorPopUp("Something went wrong! Please Try again!")
      console.log(e);
    }
  }

  if (loading) {
    // return <div>Loading exam details...</div>;
    return  <div className="ht80vh w-100 d-flex justify-content-center align-items-center">
              <SpinnerLoader />
            </div>;

  }

  if (error) {
    return <div> <div class="no-exams"><div class="no-exams-text-container"><h2>Sorry :(</h2><p>{error}</p></div></div></div>;
  }

  return (
    <div className='startexam-instructions-container'>
      {exam ? (
        <div className='row align-items-center'>
          <div className='col-md-8'>
            <div className='exam-instructions-container'>
              <h2 className='instructions-header'>NexusIQ Solutions Online Examination System</h2>
              <div className='exams-details'>
                <p className='mb-1 read-header'><strong>Please read the following instructions carefully.</strong></p>
                <ul>
                  <li>Arrange for stable Internet connectivity. Giving examination on
                    Laptop or Desktop is highly recommended. It is recommended to use web browser such as Mozilla and Chrome
                    browsers etc.</li>
                  <li>Close all browsers/tabs before starting the online examination.</li>
                  <li><strong>Once the exam starts, do not switch to any other window/tab.
                    On doing so, your attempt may be considered as malpractice and your exam may
                    get terminated.</strong></li>
                  <li>Do Not Pickup/Receive the phone calls during the exam. This also will be
                    treated as changing the window.</li>
                  <li>Do not use the back button of keyboard or close button/icon to go back to
                    previous page or to close the screen.</li>
                </ul>

                {/*<p className='mb-1 read-header'><strong>Need help ?</strong></p>
                <ul>
                  <li>For any technical queries/difficulties, immediately mail with proper screenshot 
                      and student’s details to the mail <span className='need-helpline'>
                      <a href="mailto:helpline@nexusiq-solutions.com">
                        helpline@nexusiq-solutions.com
                      </a>
                    </span>.
                  </li>
                </ul>*/}

                <div className='exam-info-container'>
                  <div className='row'>
                    <div className='col-12'>
                      <h2 className='exam-name'><span>{exam.examName}</span></h2>
                    </div>
                  </div>
                  <div className='row g-0'>
                    <div className='col-md-4 exam-info'>
                      <p>
                        <strong>Duration:</strong> {exam.duration}
                      </p>
                    </div>
                    <div className='col-md-4 exam-info'>
                      <p>
                        <strong>Total Questions:</strong> {exam.totalQuestions}
                      </p>

                    </div>
                    <div className='col-md-4 exam-info'>
                      <p>
                        <strong>Total Marks:</strong> {exam.totalMarks}
                      </p>
                    </div>
                  </div>
                </div>

              </div>

            </div>
          </div>
          <div className='col-md-4'>
            <p className='instructions-img-container'>
              <img src={instructions} alt='Exam Instructions' className='img-fluid' />
            </p>
            <div className='start-exam-button-container'>
              <p className='text-center mb-3'>
                <input className="form-check-input check-instructions-checkbox" type="checkbox" value={instructionsChecked} id="flexCheckDefault" onChange={() => setInstructionsChecked(!instructionsChecked)} />
                <label class="form-check-label check-instructions-label" for="flexCheckDefault">
                  I have all read Instructions carefully
                </label>
              </p>
              <p className='text-center'>
                <button className='start-exam-button' onClick={startExam} disabled={!instructionsChecked}>
                  Start Exam
                </button>
              </p>
            </div>

          </div>

          <Modal show={show} className='examinee_details_form_container'>
            <Modal.Header>
              <Modal.Title>Participant Details</Modal.Title>
            </Modal.Header>
            {!submitFormLoader? 
              <>
                <Modal.Body className='examinee_details_form_elements'>
                  <div className='row align-items-center mb-3'>
                      <div className='col-md-4'>
                        <label>First Name:</label>
                      </div>
                      <div className='col-md-8'>
                        <input type="text" className='enter_details' id="unregisteredUserFormFirstName" defaultValue={examineeDetails.first_name} placeholder='Enter Your First Name'/>
                      </div>
                  </div>
                  <div className='row align-items-center mb-3'>
                      <div className='col-md-4'>
                        <label>Last Name:</label>
                      </div>
                      <div className='col-md-8'>
                        <input type="text" className='enter_details' id="unregisteredUserFormLastName" defaultValue={examineeDetails.last_name} placeholder='Enter Your Last Name'/>
                      </div>
                  </div>
                  <div className='row align-items-center'>
                      <div className='col-md-4'>
                        <label>Phone Number:</label>
                      </div>
                      <div className='col-md-8'>
                        <span className='country_code'>+91</span> 
                        <input type="tel" className='enter_details tel_details' id="unregisteredUserFormPhoneNumber" defaultValue={examineeDetails.contact_number} placeholder="Enter Your Contact Number"/>
                      </div>
                  </div>
              </Modal.Body>
              <Modal.Footer>
              <Button variant="primary" className='submit_details' onClick={submitUnregisteredUserForm}>
                  Submit
                </Button>
              </Modal.Footer>
              </>:
              // <>Loading....</>
              <><div className="w-100 d-flex justify-content-center align-items-center mt-4 mb-2">
              <SpinnerLoader />
            </div></>
              
            }
          </Modal>
          <Offcanvas show={errorDisplay} placement="end" style={{height: '10vh !important', zIndex: '999999999'}}>
            <Offcanvas.Header >
              <Offcanvas.Title></Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body>
              <p className="mb-0">
                {errorMessage}
              </p>
            </Offcanvas.Body>
          </Offcanvas>
        </div>


      ) : (
        <div>Exam not found</div>
      )}

    </div>
  );
}
