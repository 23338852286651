import React, { useEffect, useState, useContext } from 'react';
import axios from 'axios';
import { Bar } from 'react-chartjs-2';
import Context from '../../user_details';
import { Chart as ChartJS, BarElement, CategoryScale, LinearScale, Tooltip, Legend } from 'chart.js';

ChartJS.register(BarElement, CategoryScale, LinearScale, Tooltip, Legend);

const TopicWisePerformance = () => {
  const { user } = useContext(Context);
  const [performanceData, setPerformanceData] = useState([]);
  const [chartData, setChartData] = useState({});
  const [error, setError] = useState(null);
  const [examCount, setExamCount] = useState(10); // Default to last 10 exams

  useEffect(() => {
    if (user._id) {
      fetchTopicWisePerformance(examCount);
    }
  }, [user._id, examCount]);

  useEffect(() => {
    if (performanceData.length > 0) {
      prepareChartData(performanceData);
    }
  }, [performanceData]);

  const fetchTopicWisePerformance = async (examCount) => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/student/topic-wise-performance/${user._id}`, {
        params: { examCount }
      });
      const data = response.data;

      if (!data || data.length === 0) {
        setError('No performance data available.');
        setPerformanceData([]);
        setChartData({});
        return;
      } else {
        setPerformanceData(data);
      }
    } catch (error) {
      if (error.response && error.response.status === 404) {
        setError('No data available.');
      } else {
        console.error('Error fetching historical analysis:', error);
        setError('Failed to fetch historical analysis.');
      }
    }
  };

  const prepareChartData = (data) => {
    if (!data || !data.map) {
      setError('Invalid data format received.');
      return;
    }

    try {
      const labels = data.map(item => item.topicName);
      const marks = data.map(item => item.overallPerformancePercentage);

      setChartData({
        labels,
        datasets: [
          {
            label: 'Percentage',
            data: marks,
            backgroundColor: 'rgba(54, 162, 235, 0.7)',
            borderColor: 'rgba(54, 162, 235, 1)',
            borderWidth: 1,
            borderRadius: 5, // Rounded corners for the bars
            barThickness: 30, // Control bar width
            hoverBackgroundColor: 'rgba(54, 162, 235, 0.6)',
            hoverBorderColor: 'rgba(54, 162, 235, 1)',
            hoverBorderWidth: 2,
            shadowOffsetX: 3, // Simulating shadow effect
            shadowOffsetY: 3,
            shadowBlur: 6,
            shadowColor: 'rgba(0, 0, 0, 0.2)',
          },
        ],
      });
    } catch (err) {
      console.log(err);
    }
  };

  const handleExamCountChange = (e) => {
    setExamCount(e.target.value);
  };

  return (
    <div>
      <div className='chart-sub-container'>
        <h3>Topic-Wise Performance</h3>
        <div className='form-elements-container row float-end'>
          <div className='form-info-entry-area'>
            <select id="examCountSelect" value={examCount} onChange={handleExamCountChange} className='form-select'>
              <option value={1}>Latest Exam</option>
              <option value={5}>Last 5 exams</option>
              <option value={10}>Last 10 exams</option>
              <option value={20}>Last 20 exams</option>
              <option value="all">All</option>
            </select>
          </div>
        </div>
        {error ? (
          <p className='text-center mb-0 no-data'>{error}</p>
        ) : (
          chartData.labels && chartData.labels.length > 0 ? (
            <Bar data={chartData} options={{ responsive: true }} />
          ) : (
            <p>No data available.</p>
          )
        )}
      </div>
    </div>
  );
};

export default TopicWisePerformance;
